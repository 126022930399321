import { useState } from "react";

import { makePostApiCall } from "../../shared/makeApiCall";
import { createClient } from "pexels";

export function MainScreenViewModel(logout) {
  const [emptyModal, setEmptyModelOpen] = useState({
    open: false,
    childComponent: null,
  });
  const [videosLoaded, setVideosLoaded] = useState(true);
  const [videos, setVideos] = useState([]);

  const getVideosForMainScreen = async function (page) {
    const payload = {
      page: page,
    };
    let callResult = await makePostApiCall(`getVideosForGuest`, payload);
    if (callResult.success) {
      console.log("callResult.data.data", callResult.data.data);
      setVideos(callResult.data.data);
    } else {
      if (callResult.errorStatus === 401) {
        logout();
      }
    }
    setVideosLoaded(true);
  };

  const randomQuery = () => {
    const queries = ["Food", "Random", "Business", "Travel"];
    return queries[Math.floor(Math.random() * queries.length)];
  };

  // const getVideosForMainScreen = (length) => {
  // const client = createClient(
  //   "OaHR7vokHm4kUK2UVSHiAb55i4mVfZ0a2HSI9lYkB9EgPN3tefjobxLi"
  // );
  // const query = randomQuery();
  // client.videos
  //   .search({ query, per_page: length })
  //   .then((result) => {
  //     setVideos((oldVideos) => [...oldVideos, ...result.videos]);
  //     setVideosLoaded(true);
  //   })
  //   .catch((e) => setVideosLoaded(false));
  // };`

  return {
    emptyModal,
    getVideosForMainScreen,
    videosLoaded,
    setEmptyModelOpen,
    videos,
  };
}
