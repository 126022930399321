import { connect } from "react-redux";

import * as actions from "../../store/actions/index";

import { withRouter } from "../../shared/utility";
import Input from "../../components/controls/Input";
import { useForm } from "../../components/forms/useForm";
import ImportMediaDropzone from "../../components/importMedia/ImportMediaDropzone";
import {
  AccountCreationStatusType,
  UploadVideoViewModel,
} from "../../viewmodels/uploadVideo/UploadVideoViewModel";

import GeneralHeaderSection from "../../components/general/GeneralHeaderSection";
import LoggedOutComponent from "../../components/LoggedOutComponent";

const initialFValues = {
  name: "",
  description: "",
  video: "",
};

function UploadVideo(props) {
  const { isAuthenticated, logout } = props;

  const {
    error,
    emptyModal,
    loading,
    setEmptyModelOpen,
    uploadNewVideo,
    uploadState,
  } = UploadVideoViewModel(logout);

  const validate = (fieldValues = values) => {
    let temp = { ...errors };

    if ("name" in fieldValues) {
      temp.name = null;
      if (fieldValues.name === "") {
        temp.name = "Please give your video a name.";
      }
    }

    if ("video" in fieldValues) {
      temp.video = null;
      if (fieldValues.video === "") {
        temp.video = "Please upload a video";
      }
    }

    setErrors({
      ...temp,
    });

    if (fieldValues === values) {
      return Object.values(temp).every((x) => x === null);
    }
  };

  const { values, setValues, errors, setErrors, handleInputChange } = useForm(
    initialFValues,
    false,
    validate
  );

  const handleSubmit = () => {
    if (validate()) {
      uploadNewVideo(values);
    }
  };

  console.log("values", values);
  return (
    <div className="regular-full-screen">
      <GeneralHeaderSection title="Upload video" />
      <div className="my-2 regular-screens">
        {isAuthenticated ? (
          <div className="flex flex-1 flex-col justify-center px-6 py-12 lg:px-8">
            <div className="sm:mx-auto sm:w-full sm:max-w-sm"></div>

            <div className="mt-10 sm:mx-auto sm:w-full sm:max-w-sm">
              <div className="space-y-6">
                <div>
                  <Input
                    label="Name"
                    name="name"
                    onChange={handleInputChange}
                    value={values.name}
                    labelOn={true}
                    type="text"
                    error={errors.name}
                    className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-1.5 px-3 focus:outline-none focus:ring-sky-500 focus:border-sky-500 sm:text-sm"
                  />
                </div>
                <div>
                  <Input
                    label="Description"
                    name="description"
                    onChange={handleInputChange}
                    value={values.description}
                    labelOn={true}
                    type="text"
                    error={errors.description}
                    className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-1.5 px-3 focus:outline-none focus:ring-sky-500 focus:border-sky-500 sm:text-sm"
                  />
                </div>

                <ImportMediaDropzone
                  fileName="video"
                  handleInputChange={handleInputChange}
                  generalError={errors.video}
                />

                <div>
                  <button
                    onClick={() => handleSubmit()}
                    className="flex w-32 justify-center rounded-md bg-indigo-600 px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                  >
                    {loading ? (
                      <svg
                        className="animate-spin -ml-1 mr-3 h-5 w-5 text-white"
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                      >
                        <circle
                          className="opacity-25"
                          cx="12"
                          cy="12"
                          r="10"
                          stroke="currentColor"
                          strokeWidth="4"
                        ></circle>
                        <path
                          className="opacity-75"
                          fill="currentColor"
                          d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                        ></path>
                      </svg>
                    ) : (
                      "Upload"
                    )}
                  </button>
                </div>
              </div>
              {error ? <p className="mt-2">{error}</p> : null}
            </div>
          </div>
        ) : (
          <LoggedOutComponent />
        )}
      </div>
    </div>
  );
}

const mapStateToProps = (state) => {
  return {
    name: state.auth.name,
    email: state.auth.email,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    logout: () => dispatch(actions.logout()),
    updateAccountDetails: (payload) =>
      dispatch(actions.updateAccountDetails(payload)),
  };
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(UploadVideo)
);
