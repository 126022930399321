import { useState } from "react";

import { makePostApiCall } from "../../shared/makeApiCall";

export function MyVideosViewModel(logout) {
  const [emptyModal, setEmptyModelOpen] = useState({
    open: false,
    childComponent: null,
  });
  const [loading, setLoading] = useState(false);
  const [videos, setVideos] = useState([]);

  const getMyVideos = async function (page) {
    setLoading(true);
    const payload = {
      page: page,
    };
    let callResult = await makePostApiCall(`getMyVideos`, payload);
    setLoading(false);
    if (callResult.success) {
      setVideos(callResult.data.data);
    } else {
      if (callResult.errorStatus === 401) {
        logout();
      }
    }
  };

  const deleteVideo = async function (id) {
    let callResult = await makePostApiCall(`deleteVideo/${id}`);
    if (callResult.success) {
      const newVideos = videos.filter((element) => element.id != id);
      setVideos(newVideos);
    } else {
      if (callResult.errorStatus === 401) {
        logout();
      }
    }
  };

  return {
    deleteVideo,
    emptyModal,
    getMyVideos,
    loading,
    setEmptyModelOpen,
    videos,
  };
}
