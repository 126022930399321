import { useRef, useState, useEffect } from "react";

import useIsInViewport from "../hooks/useIsInViewport";
import ReactPlayer from "react-player";
import ControlsContainer from "./ControlsContainer";
import VideoSidebar from "./VideoSidebar";

const VideoCard = ({ index, author, description, videoURL }) => {
  const video = useRef();
  const isInViewport = useIsInViewport(video);

  const [muted, setMuted] = useState(true);

  if (isInViewport) {
    setTimeout(() => {
      video.current.play();
    }, 1000);
  }

  const togglePlay = () => {
    let currentVideo = video.current;
    if (currentVideo.paused) {
      currentVideo.play();
    } else {
      currentVideo.pause();
    }
  };

  useEffect(() => {
    if (!isInViewport) {
      video.current.pause();
    }
  }, [isInViewport]);

  return (
    <div className="slider-children">
      <video
        muted={muted ? muted : !isInViewport}
        className="video"
        ref={video}
        onClick={togglePlay}
        autoPlay={true}
        id={index}
        preload="auto"
        playsInline={true}
        loop={true}
        src={videoURL}
      ></video>
      {muted ? <ControlsContainer setMuted={setMuted} muted={muted} /> : null}

      <div className="video-content" onClick={togglePlay}>
        <p className="m-0 text-xl font-bold">@{author}</p>
        <p className="m-0">{description}</p>
      </div>
      <VideoSidebar likes={0} messages={0} shares={0} />
    </div>
  );
};

export default VideoCard;
