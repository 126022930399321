import { Link } from "react-router-dom";

const BottomNav = () => {
  return (
    <nav className="bottom-nav">
      <Link className="navbar-brand" to="/">
        <i className="fa fa-home"></i>
      </Link>
      <Link className="navbar-brand" to="/discover">
        <i className="fa fa-search"></i>
      </Link>
      <Link className="navbar-brand" to="/upload">
        <i className="fa fa-plus"></i>
      </Link>
      <Link className="navbar-brand" to="/inbox">
        <i className="fa fa-commenting"></i>
      </Link>
      <Link className="navbar-brand" to="/profile">
        <i className="fa fa-user"></i>
      </Link>
    </nav>
  );
};

export default BottomNav;
