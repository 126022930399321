const prod = {
  url: {
    API_URL: "https://api-shiijak.ogwebdesign.ca/api/",
    STORAGE_URL: "https://api-shiijak.ogwebdesign.ca/",
  },
};

const dev = {
  url: {
    API_URL: "http://localhost:8000/api/",
    STORAGE_URL: "http://localhost:8000/",
  },
};

export const config = process.env.NODE_ENV === "development" ? dev : prod;
