import { useState } from "react";

import Dropzone from "react-dropzone";

import { classNames, convertToDefEventPara } from "../../shared/utility";

export default function ImportMediaDropzone(props) {
  const { fileName, handleInputChange, generalError } = props;

  const [error, setError] = useState(null);

  return (
    <div className="mt-2">
      <div>
        <Dropzone
          accept={{ accepted: ["video/mp4", "video/quicktime", "video/MP2T"] }}
          onDrop={(acceptedFile) => {
            if (acceptedFile.length > 0) {
              handleInputChange(convertToDefEventPara(fileName, acceptedFile));
              setError(null);
            } else {
              setError("Please choose a video.");
            }
          }}
          maxFiles={1}
          multiple={false}
        >
          {({ getRootProps, getInputProps }) => (
            <div {...getRootProps({})}>
              <button className="w-32 rounded-md bg-gray-400 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-gray-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-gray-600">
                <input {...getInputProps()} />
                Choose video
              </button>
            </div>
          )}
        </Dropzone>
      </div>
      <div className={classNames(error ? "pt-2" : "hidden")}>
        <h3 className="text-lg leading-6 font-normal text-red-500">{error}</h3>
      </div>
      <div className={classNames(generalError ? "pt-2" : "hidden")}>
        <h3 className="text-lg leading-6 font-normal text-red-500">
          {generalError}
        </h3>
      </div>
    </div>
  );
}
