import { MegaphoneIcon, PlayIcon } from "@heroicons/react/16/solid";

export default function ControlsContainer(props) {
  const { setMuted, muted, onPlayPause } = props;

  return (
    <div className="control_Container">
      <div className="mid__container">
        <div
          className="icon__btn my-auto bg-indigo-600"
          onClick={() => setMuted(!muted)}
        >
          <p className="my-auto mr-2">Unmute</p>
          <MegaphoneIcon className="h-8 w-8" />
        </div>
      </div>
    </div>
  );
}
