import { connect } from "react-redux";

import * as actions from "../../store/actions/index";

import { withRouter } from "../../shared/utility";
import Input from "../../components/controls/Input";
import { useForm, Form } from "../../components/forms/useForm";
import { SignupViewModel } from "../../viewmodels/signup/SignupViewModel";

const initialFValues = {
  email: "",
  password: "",
};

function Signup(props) {
  const { authSuccess, isAuthenticated, logout } = props;

  const {
    emptyModal,
    loading,
    setEmptyModelOpen,
    createNewAccountApiCall,
    status,
  } = SignupViewModel();

  const validate = (fieldValues = values) => {
    let temp = { ...errors };

    if ("email" in fieldValues) {
      temp.email = null;
      if (fieldValues.email === "") {
        temp.email = "This field is required.";
      }
    }

    if ("password" in fieldValues) {
      temp.password = null;
      if (fieldValues.password === "") {
        temp.password = "This field is required.";
      }
    }

    // if ("newPassword_confirmation" in fieldValues) {
    //   temp.newPassword_confirmation = null;
    //   if (fieldValues.newPassword_confirmation === "") {
    //     temp.newPassword_confirmation = "This field is required.";
    //   }

    //   if (fieldValues.newPassword_confirmation !== values.newPassword) {
    //     temp.newPassword_confirmation = "The passwords do not match.";
    //   }
    // }

    setErrors({
      ...temp,
    });

    if (fieldValues === values) {
      return Object.values(temp).every((x) => x === null);
    }
  };

  const { values, setValues, errors, setErrors, handleInputChange } = useForm(
    initialFValues,
    false,
    validate
  );

  const handleSubmit = async function () {
    if (validate()) {
      const callResult = await createNewAccountApiCall(values);

      if (callResult.success) {
        authSuccess(callResult.data);
      }
    }
  };

  return (
    <div className="regular-full-screen">
      <div className="flex flex-1 flex-col justify-center px-6 py-12 lg:px-8 regular-screens">
        <div className="sm:mx-auto sm:w-full sm:max-w-sm">
          {/* <img
          className="mx-auto h-10 w-auto"
          src="https://tailwindui.com/img/logos/mark.svg?color=indigo&shade=600"
          alt="Your Company"
        /> */}
          <h2 className="mt-10 text-center text-2xl font-bold leading-9 tracking-tight text-gray-900">
            Sign up for free today
          </h2>
        </div>

        <div className="mt-10 sm:mx-auto sm:w-full sm:max-w-sm">
          <form className="space-y-6" action="#" method="POST">
            <div>
              <Input
                label="username"
                name="name"
                onChange={handleInputChange}
                value={values.name}
                labelOn={true}
                type="text"
                error={errors.name}
                className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-1.5 px-3 focus:outline-none focus:ring-sky-500 focus:border-sky-500 sm:text-sm"
              />
            </div>
            <div>
              <Input
                label="Email"
                name="email"
                onChange={handleInputChange}
                value={values.email}
                labelOn={true}
                type="text"
                error={errors.email}
                className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-1.5 px-3 focus:outline-none focus:ring-sky-500 focus:border-sky-500 sm:text-sm"
              />
            </div>

            <div>
              <Input
                label="Password"
                name="password"
                onChange={handleInputChange}
                value={values.password}
                labelOn={true}
                type="password"
                error={errors.password}
                className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-1.5 px-3 focus:outline-none focus:ring-sky-500 focus:border-sky-500 sm:text-sm"
              />
            </div>

            <div>
              <button
                type="button"
                className="flex w-full justify-center rounded-md bg-indigo-600 px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                onClick={() => handleSubmit()}
              >
                Finish
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
}

const mapStateToProps = (state) => {
  return {
    name: state.auth.name,
    email: state.auth.email,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    logout: () => dispatch(actions.logout()),
    authSuccess: (payload) => dispatch(actions.authSuccess(payload)),
  };
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Signup));
