import { UserCircleIcon } from "@heroicons/react/24/outline";
import { Link } from "react-router-dom";

export default function LoggedOutComponent() {
  return (
    <div className="vertically-center-items text-center mt-[50%]">
      <UserCircleIcon className="h-20 w-20 mx-auto" aria-hidden="true" />
      <p className="mb-3">Sign up for an account</p>
      <Link
        to="/signup"
        className="rounded-md bg-white mt-2 px-3 py-2 text-md font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:ring-gray-400"
      >
        Sign up
      </Link>
      <p className="mt-5 mb-3"> Or already have an account?</p>
      <Link
        to="/signin"
        className="rounded-md bg-white mt-2 px-3 py-2 text-md font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:ring-gray-400"
      >
        Sign in
      </Link>
    </div>
  );
}
