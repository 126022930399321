import { Navigate, Route, Routes } from "react-router-dom";
import { connect } from "react-redux";

import * as actions from "../../store/actions/index";

import { useEffect, useState } from "react";

import { withRouter } from "../../shared/utility";

import MainScreen from "../../views/mainScreen/MainScreen";
import Profile from "../../views/profile/Profile";
import Signup from "../../views/signup/Signup";
import Signin from "../../views/signin/Signin";

import { useNavigate } from "react-router-dom";
import UploadVideo from "../../views/uploadVideo/UploadVideo";
import MyVideos from "../../views/myVideos/MyVideos";

import BottomNav from "../BottomNav";

function DefaultLayout(props) {
  const { isAuthenticated, logout, userType } = props;
  const [sidebarOpen, setSidebarOpen] = useState(false);

  const navigate = useNavigate();

  useEffect(() => {
    console.log("isAuthenticated", isAuthenticated);

    if (isAuthenticated != null) {
      navigate("/");
    }
  }, [isAuthenticated]);

  return (
    <div className="parent-layout">
      <Routes>
        <Route
          path="/"
          element={<MainScreen isAuthenticated={isAuthenticated} />}
        />
        <Route
          path="/signup"
          element={<Signup isAuthenticated={isAuthenticated} />}
        />
        <Route path="/signin" element={<Signin />} />
        <Route
          path="/my-videos"
          element={
            <MyVideos isAuthenticated={isAuthenticated} logout={logout} />
          }
        />
        <Route
          path="/upload"
          element={
            <UploadVideo isAuthenticated={isAuthenticated} logout={logout} />
          }
        />

        <Route
          path="/profile"
          element={<Profile isAuthenticated={isAuthenticated} />}
        />

        <Route path="*" element={<Navigate to="/" replace />} />
      </Routes>

      <BottomNav />
    </div>
  );
}

const mapStateToProps = (state) => {
  return {
    userType: state.auth.userType,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    logout: () => dispatch(actions.logout()),
  };
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(DefaultLayout)
);
