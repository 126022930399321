import { useState } from "react";

import { makePostApiCall } from "../../shared/makeApiCall";
import { useNavigate } from "react-router-dom";

export function UploadVideoViewModel(logout) {
  const [emptyModal, setEmptyModelOpen] = useState({
    open: false,
    childComponent: null,
  });
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);
  const [uploadState, setUploadState] = useState(
    AccountCreationStatusType.Idle
  );

  const navigate = useNavigate();

  const uploadNewVideo = async function (values) {
    setError(null);
    setLoading(true);
    let callResult = await makePostApiCall(
      `uploadVideo`,
      values,
      "multipart/form-data"
    );

    if (callResult.success) {
      console.log("new video uploaded");
      navigate("/my-videos");
    } else {
      if (callResult.errorStatus === 401) {
        logout();
        return;
      }
      setError(callResult.data.message);
    }
    setLoading(false);
  };

  return {
    error,
    emptyModal,
    loading,
    setEmptyModelOpen,
    uploadNewVideo,
    uploadState,
  };
}

export class AccountCreationStatusType {
  static Success = "success";
  static Error = "error";
  static Idle = "idle";
}
