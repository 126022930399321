import { useState } from "react";
import { useNavigate } from "react-router-dom";

import { makePostApiCall } from "../../shared/makeApiCall";

export function SignupViewModel() {
  const [emptyModal, setEmptyModelOpen] = useState({
    open: false,
    childComponent: null,
  });
  const [loading, setLoading] = useState(false);

  const navigate = useNavigate();

  const createNewAccountApiCall = async function (values) {
    let callResult = await makePostApiCall("createNewAccount", values);

    if (callResult.success) {
      return callResult;
    } else {
      // setStatus(AccountCreationStatusType.Error);
    }
  };

  return {
    emptyModal,
    loading,
    setEmptyModelOpen,
    createNewAccountApiCall,
  };
}
