import { connect } from "react-redux";

import * as actions from "../../store/actions/index";

import { withRouter } from "../../shared/utility";
import GeneralHeaderSection from "../../components/general/GeneralHeaderSection";
import { Link } from "react-router-dom";
import LoggedOutComponent from "../../components/LoggedOutComponent";

function Profile(props) {
  const { isAuthenticated, logout } = props;

  console.log("hello");

  return (
    <div className="regular-full-screen">
      <GeneralHeaderSection title="Profile" />

      <div className="my-2 regular-screens">
        {isAuthenticated ? (
          <ul role="list" className="divide-y divide-gray-100 mx-2">
            <li className="py-5">
              <Link to="/my-videos">
                <p className="text-xl leading-6 text-gray-900">My Videos</p>
              </Link>
            </li>
            <li className="py-5">
              <Link to="/#">
                <p className="text-xl leading-6 text-gray-900">Settings</p>
              </Link>
            </li>
            <li className="py-5">
              <a to="/my-videos" onClick={() => logout()}>
                <p className="text-xl leading-6 text-gray-900">Logout</p>
              </a>
            </li>
          </ul>
        ) : (
          <LoggedOutComponent />
        )}
      </div>
    </div>
  );
}

const mapStateToProps = (state) => {
  return {
    name: state.auth.name,
    email: state.auth.email,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    logout: () => dispatch(actions.logout()),
    updateAccountDetails: (payload) =>
      dispatch(actions.updateAccountDetails(payload)),
  };
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(Profile)
);
