import { connect } from "react-redux";

import * as actions from "../../store/actions/index";

import { formatDate, withRouter } from "../../shared/utility";
import { MyVideosViewModel } from "../../viewmodels/myVideos/MyVideosViewModel";
import GeneralHeaderSection from "../../components/general/GeneralHeaderSection";

import { useEffect } from "react";

function MyVideos(props) {
  const { isAuthenticated, logout } = props;

  const { deleteVideo, getMyVideos, loading, setEmptyModelOpen, videos } =
    MyVideosViewModel(logout);

  useEffect(() => {
    getMyVideos(0);
  }, []);

  return (
    <div className="regular-full-screen">
      <GeneralHeaderSection title="My Videos" />

      <div className="px-2 lg:px-8 regular-screens">
        <div className="mt-8 flow-root">
          <div className="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
            <div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
              <table className="min-w-full divide-y divide-gray-300">
                <thead>
                  <tr>
                    <th
                      scope="col"
                      className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-0"
                    >
                      Name
                    </th>
                    <th
                      scope="col"
                      className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                    >
                      Date created
                    </th>
                    <th
                      scope="col"
                      className="relative py-3.5 pl-3 pr-4 sm:pr-0"
                    >
                      <span className="sr-only">Edit</span>
                    </th>
                  </tr>
                </thead>
                <tbody className="divide-y divide-gray-200">
                  {videos.length === 0 ? (
                    <tr>
                      <td className="text-center py-3" colSpan={3}>
                        You currently have no videos
                      </td>
                    </tr>
                  ) : (
                    videos.map((video) => (
                      <tr key={video.id}>
                        <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-0">
                          {video.name}
                        </td>
                        <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                          {formatDate(video.created_at)}
                        </td>
                        <td className="flex relative whitespace-nowrap py-4 pl-3 pr-4 text-right text-sm font-medium sm:pr-0">
                          <button
                            type="button"
                            className="ml-2 flex w-full justify-center rounded-md bg-red-600 px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-red-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-red-600"
                            onClick={() => deleteVideo(video.id)}
                          >
                            Delete
                          </button>
                        </td>
                      </tr>
                    ))
                  )}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

const mapStateToProps = (state) => {
  return {
    name: state.auth.name,
    email: state.auth.email,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    logout: () => dispatch(actions.logout()),
    updateAccountDetails: (payload) =>
      dispatch(actions.updateAccountDetails(payload)),
  };
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(MyVideos)
);
