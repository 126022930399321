import React, { useState } from "react";
import { HeartIcon } from "@heroicons/react/24/outline";
import {
  ChatBubbleOvalLeftEllipsisIcon,
  ShareIcon,
} from "@heroicons/react/16/solid";

function VideoSidebar({ likes, shares, messages }) {
  const [liked, setLiked] = useState(false);

  return (
    <div className="videoSidebar">
      <div className="videoSidebar__button">
        {liked ? (
          <HeartIcon className="h-7 w-7" onClick={(e) => setLiked(false)} />
        ) : (
          <HeartIcon className="h-7 w-7" onClick={(e) => setLiked(true)} />
        )}
        <p>{liked ? likes + 1 : likes}</p>
      </div>
      <div className="videoSidebar__button">
        <ChatBubbleOvalLeftEllipsisIcon className="h-7 w-7" />
        <p>{messages}</p>
      </div>
      <div className="videoSidebar__button">
        <ShareIcon className="h-7 w-7" />
        <p>{shares}</p>
      </div>
    </div>
  );
}

export default VideoSidebar;
