import { connect } from "react-redux";

import * as actions from "../../store/actions/index";

import { withRouter } from "../../shared/utility";

import { useEffect, useState } from "react";

import { MainScreenViewModel } from "../../viewmodels/mainScreen/MainScreenViewModel";
import VideoCard from "../../components/VideoCard";
import { config } from "../../constants";

function MainScreen(props) {
  const { isAuthenticated, logout } = props;

  const {
    emptyModal,
    getVideosForMainScreen,
    videosLoaded,
    setEmptyModelOpen,
    videos,
  } = MainScreenViewModel(isAuthenticated);

  useEffect(() => {
    getVideosForMainScreen(1);
  }, []);

  return (
    <div className="slider-container">
      {videosLoaded && videos.length > 0 ? (
        <>
          {videos.map((video, index) => (
            <VideoCard
              key={index}
              index={index}
              author={video.author}
              description={video.description}
              videoURL={"https://api-shiijak.ogwebdesign.ca/" + video.video_url}
            />
          ))}
        </>
      ) : (
        <p className="text-gray-900">Loading videos2...</p>
      )}
    </div>
  );
}

const mapStateToProps = (state) => {
  return {
    name: state.auth.name,
    email: state.auth.email,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    logout: () => dispatch(actions.logout()),
    updateAccountDetails: (payload) =>
      dispatch(actions.updateAccountDetails(payload)),
  };
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(MainScreen)
);
